<template>
  <div class="auth-wrapper auth-v2">
    <div class="row auth-inner m-0">
      <!-- Brand logo-->
      <a class="brand-logo">
        <logo-with-text />
      </a>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <div class="col-lg-8 d-lg-flex justify-content-center align-items-center p-5">
        <div class="w-50 d-none d-sm-block  d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="PrepShipHub Login" />
        </div>
        <!-- <iframe
          src="https://l.feathr.co/Prosper2023/prepshiphub"
          style="width:100%; height:100%;"
          frameborder="0"
        /> -->
      </div>
      <!-- /Left Text-->

      <!-- Login-->
      <div class="col-lg-4 auth-bg d-flex">
        <div class="card card-developer-meetup align-self-center w-100">
          <div class="mx-lg-auto bg-light rounded p-1 mb-3">
            <img :src="logo" height="180">
          </div>
          <div class="card-body">
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">
                  {{ today.month }}
                </h6>
                <h3 class="mb-0">
                  {{ today.day }}
                </h3>
              </div>
              <div class="my-auto">
                <h4 class="card-title mb-25">
                  Welcome to {{ tenant.companyName }}!
                </h4>
                <p class="card-text mb-0">
                  Please sign-in to your account and start the adventure
                </p>
              </div>
            </div>
            <div v-if="tenant.address" class="media">
              <div class="media-aside mr-1 align-self-start">
                <span class="b-avatar badge-light-primary rounded">
                  <span class="b-avatar-custom">
                    <b-icon icon="pin-map-fill" font-scale="1.6" />
                  </span>
                </span>
              </div>
              <div class="media-body">
                <h6 class="mb-0">
                  Main Office Location
                </h6>
                <small>{{ tenant.address }}</small>
              </div>
            </div>
            <div>
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <b-form-group label="Username or Email" label-for="login-username">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-username"
                        v-model="userName"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="login-username"
                        placeholder="Username or Email"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          v-b-tooltip.hover.left="'Your prepshiphub username.'"
                          icon="HelpCircleIcon"
                          size="18"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
            </div>
            <div v-if="true" class="divider mb-2">
                  <div class="divider-text">
                    or
                  </div>
                </div>
                <p v-if="true" class="text-center mt-2">
                  <span>Do you need an account?</span>
                  <router-link :to="{
                    name: 'auth-register',
                    query: { registerStoreUrl: $route.query.registerStoreUrl } }"
                  >
                  Register</router-link>
                </p>
          </div>
        </div>
      </div>
      <!-- /Login-->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import LogoWithText from '@core/layouts/components/LogoWithText.vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import settingsService from '@/http/requests/common/settings'
import tenantService from '@/http/requests/tenant/tenan.settings'
import tenantApi from '@/http/requests/system/tenantService'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import SettingsTypeKeyEnum from '@/enums/settings-type-key.enum'
import themes from 'devextreme/ui/themes'
import { Notify } from '@robustshell/utils/index'
// eslint-disable-next-line import/extensions
const mode = process.env.VUE_APP_APPLICATION_MODE
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    'logo-with-text': LogoWithText,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      query: {},
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules
      required,
      tenantKey: '',
      tenant: {
        name: 'psh',
        companyName: 'PrepShipHub',
        address: '',
        phone: '',
        email: '',
      },
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/access_account.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    logo() {
      let url = ''
      if (this.tenant.tenantLogoUrl) {
        url = this.tenant.tenantLogoUrl
      } else {
        // eslint-disable-next-line import/no-dynamic-require
        url = require(`@/assets/images/logo/logo-${this.tenant.name}.svg`)
      }
      return url
    },
    today() {
      let day = null
      let month = null
      const today = moment()
      day = today.format('D')
      month = today.format('MMM')
      return {
        day: day,
        month: month,
      }
    },
    decodedRegisterStoreUrl() {
      let url = null
      if (this.$route.query.registerStoreUrl !== undefined) {
        url = decodeURIComponent(this.$route.query.registerStoreUrl)
      }
      return url
    },
  },
  created() {
    this.tenantKey = this.$route.params.tenantKey
  },
  async mounted() {
    await this.getTenantInfo(this.tenantKey)
  },
  methods: {
    nullCheck(value) {
      return value != null && value !== undefined ? value : ''
    },
    async getTenantInfo(id) {
      if (!id) {
        return
      }
      try {
        const result = await tenantApi.getTenantInfo(id)
        let address = ''
        if (result.address) {
          address = `${this.nullCheck(result.address.city)}, ${this.nullCheck(result.address.state)} ${this.nullCheck(result.address.zipcode)}, ${this.nullCheck(result.address.country)}`
        }
        this.tenant = {
          name: result.tenantId.toLowerCase(),
          companyName: result.companyName,
          address: address,
          phone: result.phone,
          email: result.email,
          tenantLogoUrl: result.tenantUrl,
        }
      } catch (err) {
        Notify.error('Could not get the tenant information')
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login(
              {
                username: this.userName.toLowerCase(),
                password: this.password,
                rememberMe: true,
                registerStoreUrl: this.decodedRegisterStoreUrl,
              },
              { Authorization: null },
            )
            .then(async response => {
              const { userData } = response.data
              // Todo: Backende eklendikten sonra silinecek.
              userData.isAmazonAuthorize = false
              useJwt.setToken(response.data.accessToken)
              if (response.data.refreshToken) {
                useJwt.setRefreshToken(response.data.refreshToken)
              }
              localStorage.setItem('loginDate', moment().valueOf())
              if (userData.ability) {
                userData.ability.push({ action: 'read', subject: 'resources_home' })
                if (userData.role.roleName !== 'CustomerNewUser') {
                  userData.ability.push({ action: 'read', subject: 'resources_common' })
                }
                if ((mode === 'development' || mode === 'awsdev' || mode === 'staging') && userData.admin && userData.admin === true) {
                  const routerData = this.$router.options.routes.map(result => result.meta)
                  routerData.forEach(routerItem => {
                    if (routerItem && routerItem.resource && routerItem.action) {
                      if (!(userData.ability.findIndex(a => a.action === routerItem.action && a.subject === routerItem.resource) >= 0)) {
                        userData.ability.push({ action: routerItem.action, subject: routerItem.resource })
                      }
                    }
                  })
                }
                this.$ability.update(userData.ability)
              }
              localStorage.setItem('userData', JSON.stringify(userData))
              const currentTenantSettings = await tenantService.currentTenantSettings()
              const tenantSettings = currentTenantSettings.data.map(item => {
                const container = {}
                container[item.key] = item
                container.tenant = item.tenantId.toLowerCase()
                return container
              })
              localStorage.setItem('currentTenant', JSON.stringify(tenantSettings))
              return userData
            })
            .then(async userData => {
              const setting = await settingsService.getByReferenceKey(referenceTypeEnum.USER.value, userData.id, SettingsTypeKeyEnum.CURRENT_USER_THEME_SETTING.value)
              const { id, value } = setting
              if (id) {
                const themeSettings = JSON.parse(value)
                const { themeSkin, themeComponentSkin, themeLayoutType } = themeSettings
                store.commit('appConfig/UPDATE_LAYOUT_TYPE', themeLayoutType)
                store.commit('appConfig/UPDATE_SKIN', themeSkin)
                themes.current(themeComponentSkin)
              }
              return userData
            })
            .then(userData => {
              const { name } = getHomeRouteForLoggedInUser(userData.role.roleName)

              const urlParams = {}

              if (this.checkWalmartCallbackUrl()) {
                urlParams.auth = 'walmart'
              }

              if (name === 'route-account-my-company') {
                urlParams.companyId = 0
              }

              this.$router
                .push({ name: name, query: urlParams })
                .then(() => {
                  Notify.success(`Welcome ${userData.fullname || userData.username}. You have successfully logged in as ${userData.role.roleName}. Now you can start to explore!`)
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
        }
      })
    },
    checkWalmartCallbackUrl() {
      const queryParams = { ...this.$route.query }
      if (queryParams.walmartCallbackUri && queryParams.walmartCallbackUri !== '') {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
