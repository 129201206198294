var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',{staticClass:"row auth-inner m-0"},[_c('a',{staticClass:"brand-logo"},[_c('logo-with-text')],1),_c('div',{staticClass:"col-lg-8 d-lg-flex justify-content-center align-items-center p-5"},[_c('div',{staticClass:"w-50 d-none d-sm-block  d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"PrepShipHub Login"}})],1)]),_c('div',{staticClass:"col-lg-4 auth-bg d-flex"},[_c('div',{staticClass:"card card-developer-meetup align-self-center w-100"},[_c('div',{staticClass:"mx-lg-auto bg-light rounded p-1 mb-3"},[_c('img',{attrs:{"src":_vm.logo,"height":"180"}})]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"meetup-header d-flex align-items-center"},[_c('div',{staticClass:"meetup-day"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.today.month)+" ")]),_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.today.day)+" ")])]),_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"card-title mb-25"},[_vm._v(" Welcome to "+_vm._s(_vm.tenant.companyName)+"! ")]),_c('p',{staticClass:"card-text mb-0"},[_vm._v(" Please sign-in to your account and start the adventure ")])])]),(_vm.tenant.address)?_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-aside mr-1 align-self-start"},[_c('span',{staticClass:"b-avatar badge-light-primary rounded"},[_c('span',{staticClass:"b-avatar-custom"},[_c('b-icon',{attrs:{"icon":"pin-map-fill","font-scale":"1.6"}})],1)])]),_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" Main Office Location ")]),_c('small',[_vm._v(_vm._s(_vm.tenant.address))])])]):_vm._e(),_c('div',[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Username or Email","label-for":"login-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-username","state":errors.length > 0 ? false : null,"name":"login-username","placeholder":"Username or Email"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Your prepshiphub username.'),expression:"'Your prepshiphub username.'",modifiers:{"hover":true,"left":true}}],attrs:{"icon":"HelpCircleIcon","size":"18"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Remember Me ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Sign in ")])],1)]}}])})],1),(true)?_c('div',{staticClass:"divider mb-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" or ")])]):_vm._e(),(true)?_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Do you need an account?")]),_c('router-link',{attrs:{"to":{
                  name: 'auth-register',
                  query: { registerStoreUrl: _vm.$route.query.registerStoreUrl } }}},[_vm._v(" Register")])],1):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }